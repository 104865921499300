import React from 'react'

export default function Header() {
    return (
        <div className="header">
            <div className="simp"/>
            <div className="vLine"/>
            <div className="simpDiary">舔狗日记</div>
            <div className="vLine"/>
            <div className="hLine"/>
        </div>
    )
}
