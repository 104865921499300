export const weathers = [
    '晴',
    '晴转多云',
    '阵雨',
    '小雨',
    '中雨',
    '暴雨',
    '微风',
    '大风',
    '雾',
    '雨夹雪',
    '阴转雨',
    '晴转阴',
    '阵雨',
    '细雨',
    '暴雪',
]