import React from 'react'

export default function Credit() {
    return (
        <div className="credit">
            <a href="https://github.com/zayabighead/simp-diary">
                simp-diary
            </a>
        </div>
    )
}
